import { FormGroup } from '@angular/forms';

export function buscarCamposInvalidos(formGroup: FormGroup) {
  const camposInvalidos: any = {};
  let contemCamposInvalidos = false;

  const controls = formGroup.controls;
  for (const nome in controls) {
    if (controls[nome].invalid) {
      camposInvalidos[nome] = ['Campo Inválido'];
      contemCamposInvalidos = true;
    }
  }
  return contemCamposInvalidos ? camposInvalidos : {};
}
