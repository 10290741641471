<section>
  <div class="modal-header">
    <div class="modal-header-text">
      <i class="bx bx-crop"></i>
      <span>Editar Imagem</span>
    </div>

    <button
      class="container-btn"
      (click)="dispensar.emit()">
      <i class="bx bx-x close"></i>
    </button>
  </div>

  <div class="form-wrapper">
    <div class="modal-main">
      <div class="editor-visualizacao">
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="maintainAspectRatio"
          [aspectRatio]="aspectRatio"
          [(transform)]="transform"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()"
          (loadImageFailed)="loadImageFailed()"
          [containWithinAspectRatio]="true"
          [canvasRotation]="canvasRotation"
          format="webp"
        ></image-cropper>
        <div
          *ngIf="loading"
          class="loader">
          <i class="bx bx-loader-alt bx-spin"></i>
        </div>
      </div>
      <div class="editor-ferramentas">
        <div class="editor-ferramentas-esquerda">
          <button
            *ngFor="let proporcao of proporcoes; let i = index"
            [disabled]="loading"
            [ngClass]="{ selecionado: (buscarProporcoes(proporcao) == aspectRatio) && maintainAspectRatio }"
            class="editor-ferramentas-esquerda-proporcao"
            (click)="maintainAspectRatio = true; aspectRatio = buscarProporcoes(proporcao)">
            <span>{{ proporcao }}</span>
          </button>

          <button
            [disabled]="loading"
            [ngClass]="{ selecionado: !maintainAspectRatio }"
            class="editor-ferramentas-esquerda-proporcao"
            (click)="maintainAspectRatio = !maintainAspectRatio">
            <i class='bx bx-crop' ></i>
          </button>
        </div>
        <div class="editor-ferramentas-direita">
          <button
            [disabled]="scale < 0.3 || loading"
            class="editor-ferramentas-direita-zoom"
            (click)="zoomOut()">
            <i class="bx bx-zoom-out"></i>
          </button>
          <button
            [disabled]="loading"
            class="editor-ferramentas-direita-zoom"
            (click)="zoomIn()">
            <i class="bx bx-zoom-in"></i>
          </button>
          <button
            [disabled]="loading"
            class="editor-ferramentas-direita-zoom"
            (click)="rotateLeft()">
            <i class="bx bx-rotate-left"></i>
          </button>
          <button
            [disabled]="loading"
            class="editor-ferramentas-direita-zoom"
            (click)="rotateRight()">
            <i class="bx bx-rotate-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      class="g2-btn-two-negativo"
      type="button"
      (click)="dispensar.emit()">
      Fechar
    </button>
    <button
      (click)="atualizarComponente.emit(croppedImage)"
      [class]="'g2-btn-two'">
      <span>Salvar</span>
    </button>
  </div>
</section>
