import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageCropperComponent, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { ToastService } from '../../services/utils/toast.service';

@Component({
  standalone: true,
  imports: [ImageCropperComponent, NgIf, NgFor, NgClass],
  selector: 'app-editor-de-imagem',
  templateUrl: './editor-de-imagem.component.html',
  styleUrls: ['./editor-de-imagem.component.scss']
})
export class EditorDeImagemComponent {
  @Output() dispensar = new EventEmitter();
  @Output() atualizarComponente = new EventEmitter();
  @Input() imageChangedEvent: Event | null = null;
  @Input() proporcoes: string[] = ['16:9', '1:1'];

  public loading = true;
  public canvasRotation = 0;
  public translateH = 0;
  public translateV = 0;
  public scale = 1;
  public croppedImage: any = '';
  public aspectRatio: number = 1 / 1;
  transform: ImageTransform = {
    translateUnit: 'px'
  };
  public maintainAspectRatio: boolean = true;

  constructor(private toastService: ToastService) {}

  imageCropped(event: ImageCroppedEvent) {
    fetch(event.objectUrl ?? '')
      .then(response => response.blob())
      .then(blob => {
        const arquivo = (<HTMLInputElement>this.imageChangedEvent!.target).files![0];
        this.croppedImage = new File([blob], arquivo.name, { type: arquivo.type });
      });
  }

  imageLoaded() {
    this.loading = false;
  }
  loadImageFailed() {
    this.toastService.erro('Tipo de arquivo não suportado.');
    this.dispensar.emit();
  }

  public buscarProporcoes(proporcao: string) {
    const numeros = proporcao.split(':');
    return Number(Number(numeros[0]) / Number(numeros[1]));
  }

  public zoomOut() {
    if (this.scale > 0.2) {
      this.scale -= 0.1;
      this.transform = {
        ...this.transform,
        scale: this.scale
      };
    }
  }

  public zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  public rotateLeft() {
    this.loading = true;
    setTimeout(() => {
      this.canvasRotation--;
      this.flipAfterRotate();
    });
  }

  public rotateRight() {
    this.loading = true;
    setTimeout(() => {
      this.canvasRotation++;
      this.flipAfterRotate();
    });
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
    this.translateH = 0;
    this.translateV = 0;
  }
}
